



@font-face {
  font-family: 'Suisse-Regular';
  src: url('./Fonts/Suisse_BP_Intl_Regular.woff');
  font-display: swap;

}
@font-face {
  font-family: 'Suisse-Medium';
  src: url('./Fonts/SuisseIntl-Medium.woff');
  font-display: swap;


}
@font-face {
  font-family: 'Suisse-Antique';
  src: url('./Fonts/Suisse_BP_Intl_Antique.woff');
  font-display: swap;


}
@font-face {
  font-family: 'SuisseItalic';
  src: url('./Fonts/SuisseIntl-MediumItalic.woff');
  font-display: swap;

}
@font-face {
  font-family: 'SuisseLight';
  src: url('./Fonts/Suisse_BP_Intl_Light.otf');
  font-display: swap;

}


*, *::before, *::after {
  box-sizing: border-box;
}

:root{
  --main-color: rgb(74, 74, 74);
  --universalVar: none;
  --fontSizeXS: 11px;
  --fontSize: 13px;
  --fontSizeM: 23px;
  --fontSizeL: 40px;
  --fontSizeXL: 40px;


  }

  .dark{
    background-color: var(--main-color);
    }

html, body, .app, .app>div {
height: 100%;
}

html {
  width: 100%;
}

.App {

}

.white-content {
  background: #000000 !important;
  color: #fff !important;
}

.black {
  color: #000 !important;
  transition: ease color 200ms;
}
.black:hover {
  color: #909090 !important;
}

body {
  margin: 0;
  background-color: #fff;
  font-family: 'Suisse-Medium';
  font-size: var(--fontSize);
  line-height: 1.1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  border-radius: 10px;
  height: 100%;
  box-sizing: border-box;
  color:var(--navTextColor);
  text-align: left;
  /* user-select: none; */
 }
  button{
  background:var(--background);
  color:var(--navTextColor);
  font-size: var(--fontSize);

 }


::selection {
  /*background: #bababa5e;  */
}
::-moz-selection {
  /*background: #ffffff5e;   */
}
 .Reg {
  font-size: var(--fontSize);

 }

h1 {
  font-size: var(--fontSize);
  font-weight: normal;
  margin: 0;
  clear: both;

}
h2 {
  font-size: var(--fontSizeL);
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 60px;
  clear: both;
  letter-spacing: -0.7px;

}
h3 {
  font-size: var(--fontSize);
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 10px;
  clear: both;
  letter-spacing: 0;

}
h4 {
  font-size: var(--fontSize);
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 10px;
  clear: both;
  letter-spacing: 0;
}

.siteTitle {
  font-size: var(--fontSizeL);
  text-align: center;
  margin-bottom: 100px;
  letter-spacing: -0.7px;

}
.paddingTop30 {
  padding-top: 40px;
}

.headerWrapper {
  position: relative;
}

.marginBottom100 {
  margin-bottom: 100px;
}
.marginBottom50 {
  margin-bottom: 50px;
}

.tags {
  margin-bottom: 10px;
}

.alignCenter {
  text-align: center;
}

p {
  transition: ease all 100ms;
  margin-top: 0px;
  margin-bottom: 10px;

}

.xl {
  font-size: var(--fontSizeXL) !important;
  line-height: 1.05 !important;
  
}

.paddingNew {
  padding-left: 3px;
  padding-right: 3px;

}

.ProjectTitle {
  float: left;
}

.xsReg {
  font-size: var(--fontSizeXS);
  font-family: "Suisse-Regular";
  font-weight: 400;
}

.xxsReg {
  font-size: 10px;
  font-family: "Suisse-Medium";
  font-weight: 400;
  color: #000000;
  margin-bottom: 0px !important;
}
.xxxsReg {
  font-size: 10px;
  font-family: "Suisse-Medium";
  font-weight: 400;
  color: #000000;
  margin-bottom: 0px !important;

}

.padding30 {
  padding-left: 30px;
  padding-right: 30px;
}

.space-L {
  height: 200px;
  width: 100%;
}

.columnWrapper {
    display: flex;
    column-gap: 20px;
}
.column {
  width: 50%;
}

.column p {
margin-bottom: 50px;}

.activeFilter {
  display: flex;
  font-family: "Suisse-Medium";
}

.indexScrollable {
  width: 100%;
  height: auto;
  position: relative;
  padding-left: 28px;
  padding-right: 28px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  padding-top: 36px;

}

.blck {
color: #000 !important;
}

.storyWrapper {
  display: grid;
width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 3px;
  grid-row-gap: 50px;
  padding: 3px;
}
.storyImage {
width: 100%;
}

.storyInfo {
width: 100%;
text-align: center;
color: #9b9b9b;
margin-bottom: 100px;
margin-top: 100px;

}

.storyTeaser{
padding-left: 30px;
padding-right: 10px;
  }

.storyContent {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 540px;
}

.storyContent p {
margin-bottom: 40px;
}

.indexScrollable::-webkit-scrollbar {
  width: 0px;
}

.indexScrollable::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.indexScrollable::-webkit-scrollbar-track {
  background-color: transparent;
}

.whiteGradient {
  pointer-events: none;
  height: 15vw;

  position: sticky;
  bottom: -2px;
  width: 100%;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.85) 30%, rgba(255, 255, 255, 0) 100%);
  z-index: 999;
}


.abstract {
 font-size: var(--fontSize);
 color: rgb(200, 200, 200);
}
.left {
  float: left;
}
.right {
  float: right;
}
.hide {
  display: none;
}
.title { grid-area: 1 / 1 / 2 / 2;
cursor: pointer;}
.tag-cell { grid-area: 1 / 2 / 2 / 3; }
.date { grid-area: 1 / 3 / 2 / 4;
text-align: right;}

.header {
  width: 100%;
  float: left;
  position: relative;
}

.none {
  display: none !important;
}

.fullscreen-container {
width: 100vw;
height: 100vh;
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
background-color: #fff;
display: flex;
justify-content: center;
align-items: center;
z-index: 999999;
}
.opener {
  font-size: 100px;
  text-align: center;
}



.project-title {
  display: grid;
  grid-template-columns: 2fr 1.5fr 0.5fr;
    grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  transition: ease all 100ms;

}
.title-wrapper {
  display: flex;
justify-content: center;
align-items: center;
}
.media-title {
  width: auto;
cursor: pointer;
transition: ease opacity 300ms;
display: flex;
justify-content: center;
align-items: center;
}
.media-title:hover {
  opacity: 0.5;
}
.media-title:hover .plusimg {
  opacity: 1;

}
.searchbutton:hover .magnifyimg {
  opacity: 1;


}
.plusimg {
  opacity: 0;
  transition: ease all 300ms;
  display: block;
  float: left;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: rgb(236, 236, 236);
  border-radius: 30px;
  padding: 5px;
}

.ego {
  width: 31%;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
}

.magnifyimg {

  opacity: 1;
  transition: ease background-color 300ms;
  float: left;
  margin-right: 4px;
  margin-left: 2px;
padding-bottom: 16px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.plus-title {
line-height: 10px;}
.project-title-ex {
  display: grid;
  grid-template-columns: 2fr 1.5fr 0.5fr;
    grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  transition: ease all 100ms;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 9999;
  position: absolute;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);

}
.project-title p:hover {
}

.project-columns {
  width: 100%;

  }

.black-bg {
  background-color: #000;
}


.div1 {
  width: 50%;
  float: left;

}
.div2 {
  width: 75%;
  float: left;
  text-indent: 28px;
  background: -webkit-linear-gradient(#000, rgb(250, 250, 250));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.parent {
}
.image-container {
  margin-top: 0px;
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.image-container-ex {
  margin-top: 0px;
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.image-center {
  height: 80vh;
  max-width: 100%;
}
.image-center-less {
  height: 100vh;
  max-width: 100%;
}
.info-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1024px;
  padding-top: 8px !important;
  margin-bottom: 30px;
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 17px;
}
.imprint-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1024px;
  padding-left: 10px;
  padding-right: 10px;

}

.imprintContent {
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
        padding-left: 28px;
  padding-right: 28px;

}
.image-container-full {
  margin-top: 0px;
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}


.description {

}
#root {
  white-space: pre-line;
}
.avatar {
  position: relative;
  width: 60px;
  margin-right: 40px;

}

.dot {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: greenyellow;
}

.online {
  position: fixed;
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
  width: auto;
  z-index: 999;
  bottom: 16px;
  left: 16px;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.541);
  padding-right: 40px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.219);
  backdrop-filter: blur(20px);
}
.username {
  float: left;
}
.user-image {
  width: 60px;
  height: 60px;
  padding: 3px;
  float: left;
  border-radius: 60px;

}


button {
  background: none;
  border: none;
  font-family: 'Suisse-Medium';
  font-size: 17px;
  padding: 0;
  cursor: pointer;
  transition: ease all 100ms;

}
button:hover {
  color: #909090;
}
a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: ease all 200ms;
  draggable: false;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;

}
a:hover {

}

a
.contact-wrapper {
  padding-bottom: 20px;
}

.intro {
  width: 100%;
  display: block;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;


  }

.projects {
  width: 100%;
  height: auto;
clear: both;
padding-right: 10px;
padding-left: 10px;

}




.centeredContent {



}




.lead {
  padding-bottom: 200px;
  background-color: rgb(0, 0, 0);
  padding-top: 10px;
  position: relative;

}


.lead p {
  font-size: 60px;
  font-family: "Suisse-Medium";
  line-height: 0.9;
  margin-left: auto;
margin-right: auto;
width: 75%;
text-indent: 200px;
color: #fff;
}

.marker {
}

.markerLeft {
  padding-left: 10px;
  padding-top: 10px;
  top:0;
  left:0;
  display: block;
  position: absolute;
}



.heading {
  padding: 10px;
  height: 80vh;
  background-color: #000;
  text-align: center;
  display: flex;
  justify-content: space-between;
flex-direction: column;
color: #fff !important;
}

.heading h1 {
height: 100%;
display: flex;
display: flex;
  justify-content: center;
flex-direction: column;
font-size: 60px;
}

.heading h2 {
  height: 100%;
  display: flex;
  display: flex;
    justify-content: center;
  flex-direction: column;
  font-size: 60px;
  }

.colorBlock {
  position: relative;
width: 100%;
padding: 10px;

}
.colorBlock1 {
  position: relative;
width: 100%;
padding: 10px;
background-color: #000000;

}

.colorBlock p {
  font-size: 26px;
  line-height: 1;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  text-indent: 50px;
}
.colorBlock1 p {
  font-size: 26px;
  line-height: 1;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-indent: 50px;
  color: rgb(255, 255, 255);
}

.colorBlock1 img {
width: 300px;
margin-left: auto;
margin-right: auto;
}

.highlight {
  padding-top: 100px;
  padding-bottom: 100px;
}

.highlight p{
  /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black; */
  font-size: 60px;
  line-height: 0.9;
  margin-left: auto;
margin-right: auto;
width: 75%;
text-indent: 200px;
color: #000000;

}

.textLarge h2 {
  font-size: 32px;
  line-height: 0.9;

}

.footer {
  width: 100%;
  padding-bottom: 80px;
  padding-left: 10px;
  padding-top: 40px;
  float: left;
  font-size: var(--fontSize);
  color: rgb(199, 199, 199);
  text-align: center;
}







.slider-controls {
  padding-top: 8px !important;
  margin-top: 8px;

}

.next {
  cursor: pointer;
  transition: ease all 100ms;
  float: right;
}

.next:hover {
  color: rgb(145, 145, 145);

}

.prev {
  cursor: pointer;
  transition: ease all 100ms;
  float: left;

}

.prev:hover {
  color: rgb(145, 145, 145);

}



.control {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  color: #494646;
  font-size: 15px;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  mix-blend-mode: difference;
  transition: ease all 300ms;
  transition-delay: 100ms;
  cursor: pointer;


}

.sectionTitle {
  margin-top: 100px;
  margin-bottom: 50px;
}



.control:hover {

  opacity: 1  ;

}


video {
  width: 100%;
  max-width: 100%;
  position: relative;

}
.video {
  height: 80vh !important;

}
.video-less {
  height: 100vh !important;

}

.video-grid {
  width: 100px;
  transition: ease all 200ms ;
}
.video-full {
width: 1200px;
}
.closeimg {
 float: left;
  width: 10px;
  height: 14px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 10px;
  margin-right: 6px;

}

.svg {
  float: left;
   width: 22px;
   height: 22px;
   cursor: pointer;
   margin-top: 10px;
   margin-right: 16px;
 stroke: #000000 !important;
fill: #000;
 }

 .tagItem {
  font-size: var(--fontSizeM);
  padding: 10px;
  float: left;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.2);
 }

 .activeTag {
  background-color: #fff;
  box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.2);
}
 .active:hover  .svg {
opacity: 0.1;
}

.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  margin-bottom: 8px; /* Adjust the negative margin as needed */
}

.active:hover:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  margin-bottom: 8px; /* Adjust the negative margin as needed */
}

.closeimg2 {
  display: block;
float: left;
  margin-right: 2px;
  width: 10px;
  height: 10px;
  margin-top: 2px;
  cursor: pointer;
}
input {

}

input[type=checkbox] {
  display: none;
  }
  input[type=button] {
    display: none;
    }

  input[type=checkbox]:checked + span {
opacity: 0.4;    }

.tag-container {
  margin-top: 12px;
}

.tag {
padding: 1px 6px;
border-radius: 3px;
margin-right: 2px;
cursor:default;
float: left;

margin-bottom: 4px;
transition: ease all 300ms ;
cursor: pointer;
}
.tag-project {
  margin-right: 6px;
  cursor:default;
  color: rgb(0, 0, 0);
  transition: ease all 300ms ;
  cursor: pointer;
  font-size: 16px;

  }

  .filter-tag {
    cursor: pointer;
    transition: ease all 200ms ;

  }
  .filter-name {
    cursor: pointer;
    transition: ease all 200ms ;
    text-transform: uppercase;

  }
  .filter-tag:hover {
    color: rgb(199, 199, 199);
    opacity: 0.8;

  }
  .filter-name:hover {
    color: rgb(199, 199, 199);
    opacity: 0.8;

  }

  .experimental-grid {

  }
  .experimental-projects {
    scroll-snap-type: y mandatory;

  }

  .space {
    height: 80px;
    width: 100%;
  }


  .top-bar {
    touch-action: none;
    -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
    height: 56px;
    width: 360px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
background-color: rgb(255, 255, 255);
box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.2);
padding-right: 10px;
overflow-x: auto;
overflow-y: hidden;
white-space:nowrap;
padding-left: 10px;
font-size: 13px;
border-radius: 1px;
cursor: move;
z-index: 99999999 !important;
transition: ease all 300ms ;
  }

  .top-bar:hover {

  }

  .nav {
    position: relative;
    font-size: var(--fontSize);
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    display: flex;
    justify-content:center;
    align-items: center;
    touch-action: none;
 
 
 }

nav a {
  margin-left: 50px;
  margin-right: 50px;

}

 .HeadBar {
  width: 100%;
  text-align: center;
  padding: 3px;
  position: relative;
  z-index: 9999;
  color: #000000;
  font-size: var(--fontSizeXS);
  margin-bottom: 30px;
 }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
  }

  .circle {
background-color: rgb(215, 215, 215) !important;
width: 20px;
height: 20px;
border-radius: 50% !important;

  }





  .first { grid-area: 1 / 1 / 2 / 2; align-items: center; display: flex; padding-top: 6px;

    padding-bottom: 6px;}
  .second { grid-area: 1 / 2 / 2 / 3; align-items: center; display: flex;
  }
  .third { grid-area: 1 / 3 / 2 / 4; align-items: center; display: flex; padding-top: 6px;

    padding-bottom: 6px;}
  .fourth { grid-area: 1 / 4 / 2 / 5; align-items: center; display: flex ;
  }




.section-title {
float: left;
text-align: left;

  }



  .flex-100 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
.project-title:hover .tag-project {

}

.red {
  color: #f00;
  margin-left: auto;
  margin-right: auto;
}

.project-grid {
  display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(auto-fill);
grid-column-gap: 0px;
grid-row-gap: 0px;
}

.searchbar {
font-weight: normal;
font-family: "Suisse-Regular";
clear: both;
color: rgb(0, 0, 0);
transition: ease all 300ms ;

}


.filter-chip {
  font-size: 100px;
font-weight: normal;
float: left;
clear: both;
color: rgb(0, 0, 0);
transition: ease all 300ms ;


}

.slideIn {
  width: 100vw;
  height: 100vh;
  clear: both;
  position: absolute;
  background-color: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  display: none;
}

.square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 20px rgba(255, 255, 255, 0); */
  z-index: 9999;
  position: relative ;
transition: ease all 300ms ;
cursor: move;
margin-bottom: 10px;
background: transparent;
/* box-shadow: 0px 0px 20px rgba(196, 196, 196, 0.747); */


}

.hide-responsive {
  display: block;
}

.square:hover {
box-shadow: 0px 0px 20px rgba(196, 196, 196, 0.747);
}
.image-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
touch-action: none;
z-index: 9999;
float: left;
position: relative ;
z-index: 9998;
margin-left: 10px;
margin-right: 10px;
margin-bottom: 40px;

}
.info-wrapper {
  touch-action: none;
  position: relative ;
  margin-left: auto;
  margin-right: auto;
clear:both;
max-width: 1024px;
}



.image-wrapper:hover  {
z-index: 9999;
  }

.image-wrapper:hover .size {
opacity: 0.6;
}



.img-blocker {
  position: absolute;
width: 100%;
height: 100%;
 z-index: 9999;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.pulse {


}
.loadable {
  overflow: hidden;
  background-color: none;
display: flex;
  justify-content: center;
height: auto;
}
img {
  display: block;
}

.marginTop {
  margin-top: 10px;
}

input[type='range'] {
	-webkit-appearance: none;
	height: 28px;
  border-radius:1px;
  background-color: var(--universalVar);
  padding-left: 5px;
  padding-right: 5px;
  transition: ease all 300ms;
  width: 80px;

}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 20px;
	width: 1px;
	background: #ffffff;
	cursor: pointer;
transition: ease all 300ms;
}


@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}



.size {
  font-size: 12px;
  text-align: center;
  opacity: 1;
  transition: ease all 200ms ;
  position: relative;
z-index: -90;
}

.project-title:hover {
  opacity: 0.4;
}

.searchbar input {
  border: none;
  font-family: "Suisse-Regular";

  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;

  font-size: 80px;
font-weight: normal;
color: rgb(234, 234, 234);

transition: ease all 300ms ;
margin: 0;
border-radius: 6px;
z-index: 999;
transition: ease all 300ms;

}

.searched {
  background-color: rgb(115, 255, 0);

}

.icon {
  width: 100px;
}
.cross {
  width: 120px;
  height: 120px;
  padding: 20px;
  border-radius: 100px;
  cursor: pointer;
  transition: ease all 300ms;
  opacity: 1;
  margin-right: 40px;
}
.cross:hover {
transform: scale(110%);
background-color: rgba(236, 236, 236, 0.343);


}
.searchbutton {
  cursor: pointer;
  transition: ease opacity 300ms;
display: flex;
align-items: center;
  float: left;
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-right: 6px;
  padding-left: 6px;
  margin-left: 1px;
  margin-right: 1px;
border-radius:1px;
color: #fff;
  background-color:var(--universalVar);
  ;
}
.searchbutton:hover {
}

.searchbar input:hover {
  transition: ease all 300ms;

}
.searchbar input:focus {
 outline: none;
 -webkit-appearance: none;
opacity: 1;


}
.searchbar input:focus label {



 }


 input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;

  height: 20px;
  width: 20px;
background-color: #000;}

.inline {
  font-size: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
padding-left: 10px;
padding-right: 10px;


}

.wrap {
font-size: 15px;
display: flex;
float: left;
align-items: center;
  float: left;
  color: rgb(0, 0, 0);
  padding-top: 6px;
  padding-bottom: 4px;
  padding-right: 6px;
  padding-left: 6px;
  margin-left: 1px;
  margin-right: 1px;
border-radius:1px;
background-color:var(--universalVar);
}

.inline:hover {



}
.animated-title {
  margin-right:20px;
  background-color: #fff;
  padding-left:24px;
  border-radius: 4px;
  padding-right:24px;
}
.animated-title-no {
  margin-right:17px;


}
.active-tag {
  transition: ease all 300ms;
  cursor: pointer;


}
.active-tag:hover {
  color: rgb(224, 224, 224);
}
.tag-wrapper {
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-right: 6px;
  padding-left: 4px;
  margin-left: 1px;
  margin-right: 1px;
border-radius:1px;
  box-shadow: 0px 0px 10px rgba(174, 174, 174, 0.343);
transition: ease opacity 300ms;
opacity: 1;
background-color:var(--universalVar);

}


.Web   {
  background-color: rgba(253, 161, 161);
}
.Print   {
  background-color: rgba(123, 221, 128);
}
.Corporate  {
  background-color: rgba(255, 229, 111);
}
.Imagery   {
  background-color: rgba(145, 121, 255);
}
.Poster   {
  background-color: rgb(255, 229, 174);
}
.Practice   {
  background-color: rgba(103, 210, 243);
}
.Logotype   {
  background-color: rgba(165, 122, 93);
}
.Photography   {
  background-color: rgba(201, 120, 67);
}
.Typography   {
  background-color: rgba(140, 154, 233);
}
.Installation   {
  background-color: rgba(241, 82, 255);
}
.Video   {
  background-color: rgba(39, 209, 186);
}
.Object   {
  background-color: rgba(142, 94, 255);
}

.tag-wrapper:hover {


}

.searchbar input:focus::placeholder {
  color: rgb(234, 234, 234);
  opacity: 0.7;

 }

.searchbar .placeholder {
  position: absolute;
  left: 12px;
  bottom: 50%;
  top: 22px;

  transform: translateY(-50%);
  width: calc(100% - 24px);
  color: rgb(234, 234, 234);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(234, 234, 234);
  opacity: 0.7;
  transition: ease all 300ms ;

}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(0, 0, 0);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(0, 0, 0);
}

.archive {
  float: left;
  clear: both;
  width: 100%;
}

.image-grid {
  width: 100%;
  display: grid;
margin-left: auto;
margin-right: auto;
  grid-column-gap: 40px;
  grid-row-gap: 200px;
  margin-bottom: 60px;



}

.zindex {
  z-index: 999999;
}

.upper {
  position:relative;
  z-index: 99999;
}

.img-wrap {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.thumbnail {
  transition: ease all 200ms ;
width: 100%;
object-fit: contain;
background: transparent;
transform: translate3d(0,0,0);
-webkit-transform: translate3d(0,0,0);
filter: none;
-webkit-filter: blur(0px);
will-change: transform;
transform-style: preserve-3d;
-webkit-filter: blur(0);
-webkit-transform: translateZ(0);
zoom:2;
-webkit-transform: scale(1);
transform: scale(1);

}

.pinch {
  background: transparent;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  filter: none;
-webkit-filter: blur(0px);
will-change: transform;
transform-style: preserve-3d;
object-fit: contain;
-webkit-backface-visibility: hidden;

}
.thumbnail:hover {
}
.switch-container {
position: relative;
}

.switch {
  float: right;
  width: 100%;
  display: flex;
  justify-content: end;


}
.switch a {
  text-indent: 0;
  margin-left: 12px;
}
 .active {

}

.title-container {
  width: 100%;
  display: flex;
  justify-content: start;
  padding-bottom: 20px;
padding-left: 28px;
}


.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}



.grid-project-title {
  text-align: center;
}
.lb-wrapper {
  position: fixed;
  top: 0;
                right: 0;
                bottom: 0;
                left: 0;
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
}
.lb-description {
  color: #fff;
}

.hero {
  width: 100%;
  font-size: 70px;
line-height: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Suisse-Medium";

}

.tagsMenu {
  width: 100%;
  text-align: left;
padding-left: 24px;
padding-right: 24px;
}

.activeClass {
  color: #fff !important;
}

.search-wrapper {
  width: 100vw;
  height: 100vh;
  clear: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 36px;
  z-index: 99999999;
  background-color: hsla(0, 0%, 0%, 0.398);
  overflow: scroll;
  color: #ffffff;
}
.card-wrapper {
  width: 100vw;
  height: 100vh;
  clear: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 36px;
  z-index: 99999999;
  overflow: scroll;
}
.intro-wrapper {
  width: 100vw;
  height: 100vh;
  clear: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 36px;
  z-index: 99999;
  background-color: #fff;
}
.marginLR {
margin-left: 10px;
margin-right: 10px;

}
.early-wrapper {
  width: 100vw;
  height: 100vh;
  clear: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 36px;
  z-index: 9999;
  overflow: scroll;
}
.open-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(231, 231, 231, 0.636);
  cursor: pointer;
  transition: ease all 200ms ;
}
.open-overlay:hover {
}
.overlay-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 100px;
  display: none;
}

.description-stream {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-tags {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 999999;
 margin-top: 6px;
 margin-left: 6px;
}

.image-tag {
  font-size: var(--fontSize);

  float: left;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 2px;
  padding-right: 6px;
  padding-left: 6px;
  margin-left: 1px;
  margin-right: 1px;
border-radius:1px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(174, 174, 174, 0.343);
}




@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.wrapper-center {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.sizer {
  transition: ease width 300ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
margin-right: 10px;
border-radius: 10px;

}

/* html {scroll-snap-type: y proximity; scroll-padding: 20px} */

.child {
    scroll-snap-align: start;

}






    .preload-wrapper {
      background-color: rgb(255, 255, 255);
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999999;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loading-wrapper {
      background-color: rgb(230, 230, 230);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
.no-spin {
  display: none;
}

.fake-loading {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

    .lds-spinner {
      color: official;
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      transform: scale(30%);
    z-index: 0;

    }

    .lds-spinner div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
    }
    .lds-spinner div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: rgb(110, 110, 110);
    }
    .lds-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    .lds-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    .lds-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    .lds-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    .lds-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    .lds-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    .lds-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    .lds-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    .lds-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    .lds-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    .lds-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    .lds-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    @keyframes lds-spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }


    .specs {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -90;
    }


    .floating {
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

  }

  @keyframes floating {
      from { transform: translate(0,  0px); }
      65%  { transform: translate(0, 15px); }
      to   { transform: translate(0, -0px); }
  }






 * {
	 box-sizing: border-box;
}


 .nav-item {
	 text-decoration: none;
	 transition: 0.3s;
	 margin: 0 0px;
	 z-index: 1;
	 position: relative;
   color: #000000;
   -webkit-user-drag: none;
user-select: none;
-webkit-app-region: no-drag;
touch-action: none;
font-size: var(--fontSizeXS);
margin-left: 40px;
margin-right: 40px;

}
.nav-item-disabled {
  text-decoration: none;
  transition: 0.3s;
  margin: 0 0px;
  z-index: 1;
  position: relative;
}


 .nav-item:not(.is-active):hover {
	 color: #000000 ;

}

.is-active {
  text-decoration: underline;
}
 .nav-indicator {
	 position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
	 left: 0;
	 bottom: 15px;
	 height: 3px;
	 transition: 0.4s;
	 height: 3px;
	 z-index: 1;
	 border-radius: 8px;
}
.indicator-dot {
  border-radius: 8px;
  height: 3px;
  width: 3px;
  background-color: #fff;
}

.expand {
width: 100%;
height: 50px;
display: flex;
margin-top: 100px;
justify-content: center;
opacity: 0;
transition: ease opacity 200ms;
}

.expand:hover {
  opacity: 1;
}


.headerContainer {
  width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 300px;
/* height: 100vh; */

}

.index {
  display: grid;

grid-template-columns: repeat(6, 1fr);
grid-template-rows: auto;
grid-column-gap: 4px;
grid-row-gap: 100px;
min-height: 300px;
transition: ease background-color 300ms;
}

.index:hover {
}

.index-image {
  width: 100%;
  min-width: 100px;

  margin-bottom: 16px;
}

.imageWrap {
  padding-right: 0px;
display: flex;
flex-direction: column;
justify-content: space-between;

}

.imageWrapper {
  opacity: 1;

  transition: ease opacity 300ms;
}

.imageWrapper:hover {
  opacity: 1;
}

.flexParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
  position: relative;
  opacity: 1;


}
.flexParent:hover  {
opacity: 0.2 !important;
}



.work-header {
  width: 100%;
  height: 100vh;

}


.gridView {
  width: 100%;
  display: grid;
  justify-content: center;
  row-gap: 100px;


}

.gridView p {
  float: left;
  margin-right: 30px;
  margin-bottom: 0px;
}

.imprintContainer {
  margin-top: 100px;
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 10px;
grid-row-gap: 0px;
padding-bottom: 100px;


}



.imprintContainer a {
color: #000;
text-decoration: underline;
}

.thumbnail {
  width: 100px;
}

.thumbs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 4px;
width: 100%;

}

.projectWrapper {
  padding-left: 0px;
  padding-right: 0px;

}

.projectGallery {
  display: grid;
  justify-content: center;
  row-gap: 200px;
  width: 100%;
  padding-bottom: 300px;

}
.portrait {
  min-width: auto;
  width: 100% !important;
  max-width: 2500px;
  margin-bottom: 0px;

}
.portraitmargin {
  min-width: auto;
  width: 100% !important;
  height: 400px;

}
.landscape {
  min-width: auto;
  max-height: 100vh !important;
  max-width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 0px;
}


.blogView {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  row-gap: 200px;

}

.article-wrapper {
  width: 100%;
  float: left;
  position: relative;
  height: 40vh;
  background-color: #000;
  overflow: hidden;
}
.article-title {
  position: absolute;
  z-index: 99;
  margin-top: 10px;
  margin-left: 20px;
  color: #fff;

}
.article-title h2 {
  float: left;
  clear: both;
}
.article-image {
  position: absolute;
  height: 100%;
  z-index: 99;
}

.projectHeaderSpace {
  height: 30vh;
  width: 100%;
}

.projectInfo {
  position: sticky;
  top: 3px;
  left: 28px;
  width: 20%;
  z-index: 9999;
}
.jobInfo {
  position: sticky;
  top: 10px;
  left: 28px;
  width: 20%;
  z-index: 9999;
  margin-top: 10px;
}
.projectInfo a {
color: #000;
cursor: pointer;
}

.projectInfo p {
  margin-bottom: 10px;
}

.projectInfo a:hover {
  color: #909090;
  }

  .modal {
    width: 100vw;
    background-color: #f00;
    height: 16px;
  }

  .marginRight {
    margin-right: 20px;
    padding-top: 5px !important;
    padding-bottom:5px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
border-radius: 2px;
    justify-content: center;
    display: block;
    align-items: center;
  }

img {
}

.sticky {
  width: auto;
  margin-left: 100px;
  margin-right: 100px;
  position: sticky;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 12px;
  padding-bottom: 12px;
color: #ffffff !important;
mix-blend-mode: difference !important;
}

.activeFilter {
  float: left;
}
.filterContainer {
  display: flex;
  width: 100%;

}

.floatMargin {
  float: left;


  padding-top: 2px;
}

.marginBottom {
  margin-bottom: 10px;
}

.floatRight {
  float: right !important;
  margin-right: 0 !important;
}

.caption {
  color: #000;
  width: 100%;
  text-align: left;
  margin-top: 3px;
  padding-left: 28px;
}

.animatedHeader {
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 28px;
  transition: ease all 200ms;
}

.projectVideo {
  overflow: hidden;
  margin-bottom: 100px;

}

.marginBottom400 {

}

@keyframes Blur {
  0% {   filter: blur(50px);
  }
  100% {   filter: blur(0px);
  }
}

.blur {

  animation: Blur 0.2s ;
  filter: blur(50px);
}

.searchProjects {
  border-radius: 3px;

  padding-top: 3px;
  padding-bottom: 1px;
  cursor: pointer;
  display: block;
  float: left;
  transition: all ease 300ms;
}
.searchProjects:hover {
}

.centerAll {
  width: 100%;
  height: 80vh;
display: flex;
align-items: center;
justify-content: center;
}

.cardcontainer {
  touch-action: none;
z-index: 99999999;
  width: 360px;
  height: 220px;
  background-color: #000;
  font-size: var(--fontSize);
  color: #fff;
  font-family: "Suisse-Medium";
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.width50 {
  width: 50%;
  float: left;
}

.filterListItem {
  font-family: "Suisse-Antique";
display: block;
float: left;
margin-right: 16px;
transition: ease color 100ms;
}


.filterListItem:hover {
  cursor: pointer;
  color: rgb(0, 0, 0);
}

.videoContainer {
     width: 100vw;
     height: 100vh;
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     z-index: 99999999;
     background-color: #fff;
}

.bgVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.teamWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 70px;
}
.teamImg {
 width: 100%;
}
.teamInfo {
  width: 100%;
  padding-top: 6px;
  padding-left: 28px;
}

.feelWrapper {
  height: 50vh;
}

.p33 {
  width: 33%;
  position: sticky;
  top: 10px;
}

.marginLeft {
    margin-left: 60px;
 }

.thinkWrapper {
  padding-top: 10px;
  padding-left: 28px;
}

.projectOverview {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  padding-right: 28px;
}
.titleWrapper {
   padding-left: 28px;
   padding-right: 28px;
}


























.swiper-pagination {
top: 0px !important;
left: 0px !important;
right: 0px !important;
margin-left: 30px !important;
margin-right: 30px !important;

margin-top: 10px !important;


}
.swiper-pagination {
  position: absolute;
  z-index: 99999;
  height: 1px;

  
  }
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #ffffff !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}

.swiper-wrapper {
height: 130vh !important;
background-color: #000;
z-index: 0;
}
.swiper-slide {
  height: 130vh !important;
  background-color: #000;

}

.SlideScale {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-progress-bar{
	width: 600px;
    height: 4px;
	position: relative;
	margin: 20px auto;
	background: #ffffff;
	.progress{
		height: inherit;
		left: 0;
		top: 0;
		position: absolute;
		background: #007aff;
		z-index: 1;
	}
	.progress-sections{
		left: 0;
		top: 0;
		position: absolute;
		height: inherit;
		width: inherit;
		z-index: 2;
		display: flex;
		flex-direction: row;
		span{
			flex: 1;
			height: inherit;
			border-right: 2px solid #ffffff;
			&:last-child{
				border-right-width: 0;
			}
		}
	}
}


.swiper-counter{
	width: 600px;
	margin: 0 auto;
}


.StickyWrapper {
height: 130vh;
width: 100%;
position: relative;
}
.StickyChild {
  height: 130vh;
  width: 100%;
  position: relative;


  padding-top: 30px;
}
.StickyChild2nd {
  height: 0vh;
  width: 100%;
  position: relative;
  padding-left: 28px;
  padding-right: 28px;

}
.StickyLead {
  position: sticky;
  top: 20px;
  z-index: 99999;
  color: #fff;
  font-size: var(--fontSizeL);
  line-height: 1;

  margin-top: 15px;
}
.StickyLead2nd {
  position: sticky;
  top: 0;
  z-index: 99999;
  color: #fff;
  font-size: var(--fontSizeL);
  line-height: 1;
}

.absolute {
  position: absolute;
  top: 0;
  left: 28px;
  z-index: 99999;
}

.StickySlider {

}

.blocker {
  height: 200px;
  width: 100%;
  margin-bottom: 100px;
  float: left;
}

.lrgText {
  font-size: var(--fontSizeL);
  line-height: 1;
  margin-bottom: 200px;
  margin-top: 200px;
  letter-spacing: -0.7px;

}
.lrgTextNoMargin {
  font-size: var(--fontSizeL);
  line-height: 1;
  letter-spacing: -0.7px;

}
.lrgTextIntend {
  font-size: var(--fontSizeL);
  letter-spacing: -0.7px;

  margin-left: 100px;
  line-height: 1;
  margin-bottom: 100px;
}

.blkBg {
  background-color: #ffffff;
  color: #000000 !important;
}

.blkBg button {
  color: #000000 !important;
}

.blk {
  color: #000 !important;
}

.InfoContainer {
  width: 25%;
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
padding-bottom: 100px;


}

.InfoContainer2 {
  width: 25%;

padding-bottom: 100px;


}

h3 {
  font-size: var(--fontSize);
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 25px;
  clear: both;
}

h3:not(:first-child) {
  margin-top: 50px;
}

.stickyp {
  top: 28px;
  display: block;
}
.stickyTitle {
  top: 5px;
  position: sticky;
  display: block;
}

.marginTop5 {
  margin-top: 5px;
  margin-bottom: 3px !important;
}

.mgmtImg {
  width: 100%;
}
.mgmt {
  width: 25%;
  display: grid;
grid-template-columns: repeat(1, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 50px;
grid-row-gap: 50px;
padding-bottom: 100px;
}

.mgmtImgWrapper {
}

.marginTop100 {
  margin-top: 100px;
}

.marginLeft50 {
  margin-left: 50px;
}

.centeredContent {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.titleCentered {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: fixed;
  width: 100%;
  font-size: var(--fontSizeL) !important;
}

.titleCentered h1{

  font-size: var(--fontSizeL) !important;
}

.fullSection {
  position: relative;
  width: 100%;
  height: auto;
}

.fullSection .longCopy {
  max-width: 360px;
 
  margin-bottom: 100px;
}

.InfoContainerThink {
  width: 20%;
  display: grid;
grid-template-columns: repeat(1, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
padding-bottom: 300px;
}

.wrapperBlack {
  background-color: #000;
  width: 100%;
  display: grid;
grid-template-columns: repeat(1, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
padding-bottom: 100px;
padding-left: 30px;
padding-right: 30px;
padding-top: 10px;
}

.List {
font-size: var(--fontSizeL);
color: #fff;

}

.List li {
text-align: center;
}

.List ol {
  counter-reset: elementcounter;
 }
 


.numWrapper {
display: flex;
width: 100%;
flex-direction: row;
align-items: baseline;
font-family: "SuisseItalic";

}

.num {
  float: left;
  font-size: 22px;
  margin-right: 50px;
}

.space30 {
  height: 30px;
  width: 100%;
}

.lrgTextLead {
  font-size: var(--fontSizeL);
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -0.7px;
  line-height: 1.1;
}

.centred {
  max-width: 1024px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.lrgTextLead p {
}

.lrgTextLead h1 {
  letter-spacing: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  }

.heroText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.HeroImageWrapper {
  width: 100%;
  position: absolute;
  top:0;
  height: 100vh;
}

.space100vh {
  width: 100%;
  height: 100vh;
}

ol {
  padding-left: 14px;
}
ol li {
  padding-left: 20px;
}



.centerFixed {

  z-index: 99999;
display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--fontSizeL);
  font-family: "SuisseItalic";
  position: fixed;
}

.jobsWrapper {
  height: 100vh;
  width: 100%;
  background-color: #000;
  margin-top: -100vh;
}



.white {
  color: #fff !important;
}





.container {  display: grid;
  grid-auto-flow: row; 

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto; /* Each row will be the height of its content */

  gap: 0px 0px;
  grid-template-areas:
    "left1 right1"
    "left2 right2";
    align-items: start; 
    justify-content: flex-start;

}

.container p {
  padding-bottom: 50px;
}

.left1 { grid-area: left1; }

.left2 { grid-area: left2; }


.right1 { grid-area: right1; }

.right2 { grid-area: right2; }



.maxWidth {
  max-width: 80%;
}
















@media only screen
and (min-device-width: 20px)
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {


  :root{
  --fontSizeL: 20px;
  
    }

    .columnWrapper {
      display: block;
  }

    .column {
      width: 100%;
    }


    .maxWidth {
      max-width: 100%;
    }

    .container {  display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto; /* Each row will be the height of its content */
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "left1"
        "left2"
        "left3"
        "right1"
        "right2"
        "right3";
    }
    
    .left1 { grid-area: left1; }
    
    .left2 { grid-area: left2; }
    
    .left3 { grid-area: left3; }
    
    .right1 { grid-area: right1; }
    
    .right2 { grid-area: right2; }
    
    .right3 { grid-area: right3; }
    

  .sticky {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
 
  }

  .InfoContainerThink {
    width: 100%
  }

  .mgmt {
    width: 100%;
  }

  .InfoContainer {
    width: 100% !important;
  }

 .right {
   float: left;
 }

 .image-center {
   width: 100%;
   height: auto;
   max-height: none;
 }

 .project-title p:hover {
   color: rgb(0, 0, 0);
 }
 button:hover {
   color: rgb(0, 0, 0);
 }
 .control {

 }

 .svg {
  float: left;
   width: 18px;
   height: 18px;
   cursor: pointer;
   margin-top: 3px;
   margin-right: 10px;
 stroke: #000000 !important;

 }

 .project-title-ex {
   display: inline;
 }

 .overlay-title {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 40px;
   color: rgb(0, 0, 0);
   text-align: center;
   line-height: 40px;
 }
 .filter-tag{
 }
 .tag-cell {
   display: none;
 }
 .image-center-less {
   height: 100vh;
   max-width: 100%;
   object-fit: cover;
 }

 .image-container-ex {
   margin-top: 0px;
   width: 100vw;
   height: 100vh;
   justify-content: center;
   overflow: hidden;
 }
 #display-none {
   display: none;
 }

 .animatedHeader {
  width: 100%;
  padding: 28px;
}
.indexScrollable {
  height: auto;
  padding-left: 8px;
  padding-right: 8px;
}
.whiteGradient {
  height: 30vw;
  bottom: -2px;
}


 .searchbar input {
   border: none;
   -webkit-appearance: none;
   -ms-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-color: transparent;
   font-size: 17px;
   padding-top: 8px;
   font-family: 'Suisse-Medium';
 font-weight: normal;
 width: fit-content;
 text-align: center;
 color: rgb(0, 0, 0);

 transition: ease all 300ms ;


 }
 .tag-cell button {
background-color: black;    }

input[type="button" i]  {
   background-color: black;
 border-width: 0px;    }

 .animated-title {
   margin-right: 8px;
   padding-left: 10px;
   padding-right: 10px;
   border-radius: 2px;
 }
 .animated-title-no {
   margin-right: 8px;
 }
 .inline {
   font-size: 32px;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   height: 200px;

 }
 body::-webkit-scrollbar {
   display: none; /* for Chrome, Safari, and Opera */
 }

 .imprintContainer {
  margin-top: 100px;
  display: block;
  padding-bottom: 100px;
}


 .hide-responsive {
   display: none;
 }

 .project-columns {
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: repeat(2, 1fr);
     grid-column-gap: 0px;
     grid-row-gap: 0px;
     grid-auto-rows: minmax(min-content, max-content);

     }

     .div-2 { grid-area: 1 / 1 / 2 / 2; }
     .div-1 { grid-area: 2 / 1 / 3 / 2; }

   .project-title {
     grid-template-columns:  1fr;
       grid-template-rows: repeat(2, 1fr);
     grid-column-gap: 0px;
     grid-row-gap: 0px;
     transition: ease all 100ms;

   }
   .tag-project {
     display: none;
   }
   .date {
     display: none;
   }
   button .section-title {
     display: none;
   }
   .image-grid {
     width: 100%;
     display: grid;
     grid-template-columns: repeat(1, 1fr);
     grid-template-rows: repeat(1fr);
     grid-column-gap: 20px;
     grid-row-gap: 80px;
     margin-bottom: 80px;
    justify-content: center;

   }
   .image-wrapper {
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
   position: relative;
   touch-action: none;
   background: transparent;
   transform: translate3d(0,0,0);
   -webkit-transform: translate3d(0,0,0);
   filter: none;
   -webkit-filter: blur(0);
   -webkit-transform: translateZ(0);
   margin-right: 80px;
   margin-left: 0px;

   }
   .info-wrapper {
     touch-action: none;
     position: relative ;
   }
   .wrapper-center {
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .search-wrapper {
height: auto;
display: flex;
align-items: center;
justify-content: flex-start;

   }

   .cross {
     width: 30px;
     height: 30px;
     padding: 4px;
     margin-right: 10px;
     transform: scale(100%);
     background-color: rgba(255, 255, 255, 0.548);
   }

   .searchbar {
     font-size: 17px;



   }

   .top-bar {
    touch-action: none;

width: 360px;
    display: flex;
 justify-content: center;
 padding-left: 20px;
 padding-right: 20px;
   }

 

 .first {
   justify-self: start;
 }

 .plusimg {
   opacity: 0.7;
 }

 .index {
  display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-template-rows: auto;
 grid-column-gap: 3pxx;
 grid-row-gap: 50px;
 min-height: 300px;
 }

 .projectInfo {
  position: relative;
  top: 10px;
  left: 0px;
 padding-left: 28px;
 padding-right: 28px;
   width: 100%;
   z-index: 0;
 }
 .jobInfo {
  position: sticky;
  top: 10px;
  left: 0px;
  padding-left: 28px;
  padding-right: 28px;
  width: 100%;
  z-index: 9999;
  margin-top: 10px;
}

 .titleWrapper {
  padding-left: 8px;
  padding-right: 8px;
 }

 .gridView {
  width: 100%;
  display: grid;
  justify-content: center;
  row-gap: 100px;
padding-left: 0px;
padding-right: 0px;

}

.cardcontainer {
  width: 360px;
  height: 220px;
}

  .imageWrap {
    padding-right: 0px;
  }

.hero {
font-size: 7vw;
}

.tagsMenu {
width: 100%;
text-align: left;
padding-left: 26px;
padding-right: 26px;
}

.filterListItem {
margin-right: 6px;

}

.hideResponsive {
display: none;
}

.projectWrapper {
padding-left: 0px;
padding-right: 0px;

}
.projectGallery {
display: grid;
justify-content: center;
row-gap: 50px;
width: 100%;
padding-bottom: 200px;

}

.portrait {
min-width: auto;
width: 100% !important;
max-width: 2500px;
margin-bottom: 0px;
}
.landscape {
min-width: auto;
max-height: 100vh !important;
max-width: 80vw;
}

.storyWrapper {
  display: grid;
width: 100%;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 3px;
  grid-row-gap: 50px;
}

 }



@media only screen and (max-width: 1200px) {

  :root{
    --fontSizeL: 30px;
    
      }


    .columnWrapper {
      display: block;
  }

      .column {
        width: 100%;
      }

      .maxWidth {
        max-width: 100%;
      }

      .container {  display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto; /* Each row will be the height of its content */
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
          "left1"
          "left2"
          "left3"
          "right1"
          "right2"
          "right3";
      }
      
      .left1 { grid-area: left1; }
      
      .left2 { grid-area: left2; }
      
      .left3 { grid-area: left3; }
      
      .right1 { grid-area: right1; }
      
      .right2 { grid-area: right2; }
      
      .right3 { grid-area: right3; }
      

      .storyWrapper {
        display: grid;
      width: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 3px;
        grid-row-gap: 50px;
      }

  .sticky {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
 
  }

  .InfoContainerThink {
    width: 100%
  }



.mgmt {
width: 100%;
}

.index {
 display: grid;

grid-template-columns: repeat(3, 1fr);
grid-template-rows: auto;
grid-column-gap: 3px;
grid-row-gap: 50px;
transition: ease background-color 300ms;
}
.imprintContainer {
margin-top: 100px;
display: block;
padding-bottom: 100px;
}

.InfoContainer {
width: 100% !important;
}

.projectInfo {
  position: relative;
  top: 10px;
  left: 0px;
 padding-left: 28px;
 padding-right: 28px;
   width: 100%;
   z-index: 0;
 }

 .projectGallery {
  display: grid;
  justify-content: center;
  row-gap: 50px;
  width: 100%;
  padding-bottom: 200px;
  
  }

}





/* Container for all posts 
.index {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

/* 
.flexParent {
  width: 100%; 
  margin-bottom: 20px; 
  display: flex;
  flex-direction: column;
}


.imageWrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
  gap: 10px; 
  margin-top: 20px;
}



.index-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
*/

.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/4; 
}

@media (max-width: 768px) {
  .flexParent {
    width: 100%;
  }

  .imageWrap {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}